'use strict';

$(document).ready(function () {
    const Swiper = require('swiper');
    const statsCarousels = $('.swiper-homepage-stats-carousel');
    for (var i = 0; i < statsCarousels.length; i++) {
        $(statsCarousels[i]).find('.experience-commerce_assets-homepageStatsCarouselItem').last().find('.homepage-stats-carousel-dot').removeClass('d-lg-flex');
    }
    if (statsCarousels.length > 0 && $(document).width() < 768) {
        statsCarousels.find('.experience-commerce_assets-homepageStatsCarouselItem').addClass('swiper-slide homepage-stats-carousel-slide');
        var statsSlider = new Swiper('.swiper-homepage-stats-carousel', {
            zoom: false,
            slidesPerView: 1,
            loop: true,
            centeredSlides: true,
            keyboardControl: false,
            grabCursor: true,
            autoplay: 4000,
        });
    }
});
